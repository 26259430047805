import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom/client'
import '@/assets/scss/style.scss'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import i18n from './i18n'

const Quiz = lazy(() => import('@/components/Quiz/Quiz'))
const CreateAccount = lazy(() => import('./components/CreateAccount/CreateAccount'))
const ThankYou = lazy(() => import('./components/ThankYou/ThankYou'))
const Checkout = lazy(() => import('./components/Checkout/Checkout'))
const Paywall = lazy(() => import('./components/Paywall/Paywall'))

const queryParams = new URLSearchParams(window.location.search)

// let quizVersion: boolean | string | null = localStorage.getItem('quizVersion')
// if (!quizVersion) {
// quizVersion = Math.random() < 0.5
// localStorage.setItem('quizVersion', String(quizVersion))
// if (!quizVersion) {
//   window.location.href = 'https://quiz-1.fancyface.care/'
// }
// } else {
//   quizVersion = quizVersion === 'true'
// }

const isPaywallWithApplePay = queryParams.get('cohort') === 'v1'
if (isPaywallWithApplePay) {
  localStorage.setItem('isPaywallWithApplePay', 'true')
}

document.documentElement.lang = i18n.language

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Router>
    <Suspense>
      <Routes>
        <Route
          path="/"
          element={<Navigate to={`/quiz-2/0/${queryParams ? `?${queryParams.toString()}` : ''}`} />}
        />
        <Route
          path="/quiz-2/"
          element={<Navigate to={`/quiz-2/0/${queryParams ? `?${queryParams.toString()}` : ''}`} />}
        />
        <Route
          path="/:currentScreen"
          Component={(props) => <Quiz {...props} lang={i18n.language} quizFile="quiz.json" />}
        />
        <Route
          path="/quiz-2/:currentScreen"
          Component={(props) => <Quiz {...props} lang={i18n.language} quizFile="quiz-2.json" />}
        />
        <Route
          path="/paywall/"
          Component={(props) => (
            <Paywall
              paymentMethod={'stripe'}
              paywallFile={
                process.env.REACT_APP_MODE !== 'dev'
                  ? 'paywall-new-stripe-live.json'
                  : 'paywall-new-stripe-dev.json'
              }
              {...props}
              lang={i18n.language}
            />
          )}
        />
        <Route
          path="/paywall-old/"
          Component={(props) => (
            <Paywall
              paymentMethod={'stripe'}
              paywallFile={
                process.env.REACT_APP_MODE !== 'dev' ? 'paywall-live.json' : 'paywall-test.json'
              }
              {...props}
              lang={i18n.language}
            />
          )}
        />
        <Route
          path="/paywall-2/"
          Component={(props) => (
            <Paywall
              paymentMethod={'paddle'}
              paywallFile="paywall-test.json"
              {...props}
              lang={i18n.language}
            />
          )}
        />
        <Route path="/checkout/" Component={(props) => <Checkout {...props} />} />
        <Route path="/register/" Component={(props) => <CreateAccount {...props} />} />
        <Route path="/thank-you/" Component={(props) => <ThankYou {...props} />} />
        <Route
          path="/test-register/"
          Component={(props) => <CreateAccount testMode={true} {...props} />}
        />
      </Routes>
    </Suspense>
  </Router>,
)
